<template>
    <div ref="container" class="keen-slider max-w-2xl mx-auto">
        <div class="keen-slider__slide">
            <img src="~/assets/images/partner/abarth.webp" alt="" class="w-full" />
        </div>
        <div class="keen-slider__slide">
            <img src="~/assets/images/partner/alfa-romeo.webp" alt="" class="w-full" />
        </div>
        <div class="keen-slider__slide">
            <img src="~/assets/images/partner/citroen.webp" alt="" class="w-full" />
        </div>
        <div class="keen-slider__slide">
            <img src="~/assets/images/partner/ds-automobiles.webp" alt="" class="w-full" />
        </div>
        <div class="keen-slider__slide">
            <img src="~/assets/images/partner/fiat.webp" alt="" class="w-full" />
        </div>
        <div class="keen-slider__slide">
            <img src="~/assets/images/partner/fiat-professional.webp" alt="" class="w-full" />
        </div>
        <div class="keen-slider__slide">
            <img src="~/assets/images/partner/hyundai.webp" alt="" class="w-full" />
        </div>
        <div class="keen-slider__slide">
            <img src="~/assets/images/partner/jeep.webp" alt="" class="w-full" />
        </div>
        <div class="keen-slider__slide">
            <img src="~/assets/images/partner/kia.webp" alt="" class="w-full" />
        </div>
        <div class="keen-slider__slide">
            <img src="~/assets/images/partner/lancia.webp" alt="" class="w-full" />
        </div>
        <div class="keen-slider__slide">
            <img src="~/assets/images/partner/opel.webp" alt="" class="w-full" />
        </div>
        <div class="keen-slider__slide">
            <img src="~/assets/images/partner/nissan.webp" alt="" class="w-full" />
        </div>
        <div class="keen-slider__slide">
            <img src="~/assets/images/partner/peugeot.webp" alt="" class="w-full" />
        </div>
    </div>
</template>

<script setup>
let observer = null;
const functions = {};

const [container] = useKeenSlider(
    {
        loop: true,
        mode: 'free-snap',
        slides: {
            perView: 6,
            spacing: 40,
        },
    },
    [
        (instance) => {
            let timeout;
            let mouseOver = false;

            function clearNextTimeout() {
                clearTimeout(timeout);
            }

            function nextTimeout() {
                clearTimeout(timeout);
                if (mouseOver) return;
                timeout = setTimeout(() => {
                    instance.next();
                }, 3500);
            }

            instance.on('created', () => {
                instance.container.addEventListener('mouseover', () => {
                    mouseOver = true;
                    clearNextTimeout();
                });
                instance.container.addEventListener('mouseout', () => {
                    mouseOver = false;
                    nextTimeout();
                });
                nextTimeout();
            });

            instance.on('dragStarted', clearNextTimeout);
            instance.on('animationEnded', nextTimeout);
            instance.on('updated', nextTimeout);

            functions.pause = () => clearNextTimeout();
            functions.play = () => nextTimeout();
        },
    ]
);

const handleIntersection = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            functions.play();
        } else {
            functions.pause();
        }
    });
};

onMounted(() => {
    if (container.value) {
        observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 0.1,
        });
        observer.observe(container.value);
    }
});

onUnmounted(() => {
    if (observer) {
        observer.disconnect();
    }
});
</script>
